.preloader {
  height: 100vh;
  width: 100%;
  background: #e6e6fa;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 55;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.preloader .texts-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 370px;
  font-size: 30px;
  font-weight: 800;
  overflow: hidden;
  color: gray;
}
